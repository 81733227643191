import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
import quasarUserOptions from "./quasar-user-options";
import axios from "axios";
import VueBarcode from "@chenfengyuan/vue-barcode";
import VueApexCharts from "vue3-apexcharts";
// import * as Sentry from "@sentry/vue";
const app = createApp(App);

app.use(Quasar, {
  plugins: {
    Notify,
    Loading,
    Dialog,
  },
  config: {
    loading: {},
  },
  quasarUserOptions,
});

// provide
app.provide(
  "$http",
  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
  })
);

app.provide("rupiah", (number) => {
  return Intl.NumberFormat("id", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 2,
  }).format(number);
});

app.provide("thousand", (number) => {
  return Intl.NumberFormat("id").format(number);
});

// Sentry.init({
//   app,
//   dsn: "https://a26d2c79fc746b0994e245e1f87cb44b@o4508752657842176.ingest.de.sentry.io/4508752660004944",
//   integrations: [
//     Sentry.browserTracingIntegration({ router }),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [
//     "localhost",
//     /^https:\/\/drkevin-scan.lignosoftware\.com\//,
//   ],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
// end of provide

app.use(router).use(VueApexCharts);

app.mount("#app");
